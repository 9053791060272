import './shared/edittools';

import './shared/adInsertion';
import './shared/universal-header';
import './shared/search-modal';
import './shared/native-ads';
import './shared/stream-share';
import './shared/sliders-featured';
import setupCordialService from './shared/cordialData';

import './homepage/happening-now';
import './homepage/pop-picks';
import './homepage/brandvoice-setup';
import './homepage/edition-dropdown';
import './homepage/lazy-load';
import './homepage/marketo';
import './homepage/404-disclaimer';
import './homepage/quote-of-the-day';
import './homepage/title-empower';

setupCordialService();
