import { isProd } from '../shared/clientConfigService';

/**
 * changes titles in the breaking news bar to the titles received from the api.
 * @param {Object} data titles returned for the api.
 * @param {HTMLElement} breakingNewsLinks elements in the breaking news bar.
 * @param {HTMLElement} breakingNewsBar breaking news bar.
 * @param {Boolean} didTimeOut did the api take too much time.
 */
function applyTitles(data, breakingNewsLinks, breakingNewsBar, didTimeOut) {
	if (didTimeOut) {
		return;
	}

	breakingNewsLinks.forEach((link) => {
		if (data.experiments[link.href] && data.experiments[link.href].feature) {
			const rewardData = new TextEncoder().encode(JSON.stringify({
				experimentName: link.href,
				arm: data.experiments[link.href].feature,
			}));

			link.innerHTML = data.experiments[link.href].feature;
			link.addEventListener('click', () => {
				navigator.sendBeacon(`https://ab-machine${isProd ? '' : '-stg'}.forbes.com/mab/reward`, rewardData);
			});
		}
	});
	breakingNewsBar.style.display = 'block';
}

/**
 * find alternative titles for some of the articles in the breaking news bar.
 * @param {HTMLElement} breakingNewsLinks elements in the breaking news bar.
 * @param {HTMLElement} breakingNewsBar breaking news bar.
 */
function empowerTitles(breakingNewsLinks, breakingNewsBar) {
	const linksList = [];
	let didTimeOut = false;

	breakingNewsBar.style.display = 'none';
	breakingNewsLinks.forEach((link) => {
		linksList.push({ name: encodeURI(link.href) });
		breakingNewsBar.style.display = 'block';
	});

	const experiments = JSON.stringify({ experiments: linksList });

	const timeout = setTimeout(() => {
		didTimeOut = true;
	}, 200);

	fetch(`https://ab-machine${isProd ? '' : '-stg'}.forbes.com/mab/arm`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: experiments,
	})
		.then((res) => res.json())
		.then((data = {}) => {
			clearTimeout(timeout);
			applyTitles(data, breakingNewsLinks, breakingNewsBar, didTimeOut);
		})
		.catch((error) => console.error('no data received', error));
}

document.addEventListener('DOMContentLoaded', () => {
	const breakingNewsLinks = document.querySelectorAll('.happening__title');
	const breakingNewsBar = document.querySelector('.happening-now__carousel-wrapper');

	if (breakingNewsLinks.length > 0 && breakingNewsBar) {
		empowerTitles(breakingNewsLinks, breakingNewsBar);
	}
});
