import multiclamp from '@forbesmedia/multiclamp';

document.addEventListener('DOMContentLoaded', () => {
	const happeningCarousel = document.querySelector('.happening-now__carousel');

	if (!happeningCarousel) {
		return;
	}

	function truncateTitles() {
		const titles = [...happeningCarousel.querySelectorAll('.happening__title')];
		titles.forEach((title) => {
			multiclamp(title, 2, false);
		});
	}

	function showLeftArrow() {
		const leftArrow = happeningCarousel.querySelector('.fbs-slider__control-left');

		if (leftArrow) {
			leftArrow.classList.remove('fbs-slider__control--hidden');
		}
	}

	truncateTitles();

	happeningCarousel.addEventListener('slideChangeStart', () => {
		truncateTitles();
		showLeftArrow();
	});
});
